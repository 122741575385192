import { useState } from 'react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';

interface ReturnType<T> {
    getData: () => void;
    response: T | null;
    loading: boolean;
    error: string | null;
}

/**
 * The useFetch hook can be used to make a request to any API. It has loading and error handling integrated in the hook.
 *
 * You have to specify the return type of the data of the request
 * @params getToken this is a function to get a JWT token from campaigndesigner api (This can be removed when the new authentication service is in place)
 */
const useFetch = <T>(config: AxiosRequestConfig, getToken: () => Promise<string>): ReturnType<T> => {
    const [response, setResponse] = useState<T | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getData = async () => {
        try {
            setLoading(true);

            const token = await getToken();

            if (!config.headers) config.headers = {};

            config.headers['Authorization'] = `Bearer ${token}`;

            const response = await axios(config);
            setResponse(response.data as T);
            setError(null);
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                Sentry.captureException(error);
                return setError(axiosError.message);
            }
            Sentry.captureException(error);
            setError('Unknown error: something went wrong');
        } finally {
            setLoading(false);
        }
    };

    return {
        getData,
        response,
        loading,
        error
    };
};

export default useFetch;
